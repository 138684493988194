<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div v-html="content" style="margin-top:30px">
      </div>
    </div>

  </transition>
</template>

<script>
export default {
  data () {
    return {
      bannerCode: '',
      content: `

      `,
    }
  },
  created () {
    this.bannerCode = this.$route.query.bannerCode
    this.getContent()
  },

  methods: {

    getContent () {
      this.$api.home.getBannerDetail(
        {
          bannerCode: this.bannerCode
        }
      ).then(res => {
        if (res.data.code === 200) {
          this.content = res.data.data.content
        }
      })
    }

  }
}
</script>

<style lang="less" >
img {
  width: 100%;
  height: 100%;
}
</style>